import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faExternalLinkAlt,
    faChevronRight
} from '@fortawesome/free-solid-svg-icons';

const Offerings = ({ offers }) => (
    <>
        {offers != null && offers.length > 0 &&
            <>
                {offers.map((item, i) => {
                    const isEven = i % 2 === 0 ? true : false;
                    const cssContainerClass = isEven ? '' : 'is-flex-direction-row-reverse';
                    const itemBackgroundColor = isEven ? '#31297b' : '#d92026';
                    const target = item.openinnewtab ? 'blank' : '';

                    return (
                        <div key={i} className={"columns " + cssContainerClass}>
                            <div className="column is-6 offering" style={{ 'background': itemBackgroundColor, 'color': '#fff' }}>
                                <h2 className="has-text-weight-bold is-size-4-mobile is-size-3-tablet is-size-2-widescreen">{item.title}</h2>
                                <p>{item.description}</p>
                                {item.ctabuttontext && item.ctabuttontext !== '' &&
                                    <div className="column" style={{ 'margin': '0 auto', 'textAlign': 'center' }}>
                                        <a href={item.ctabuttonlink} className="button mt-6" target={target} style={{'background': 'transparent', 'color':'#fff'}}>
                                            {item.ctabuttontext}
                                            {item.openinnewtab === false &&
                                            <FontAwesomeIcon icon={faChevronRight} style={{ 'marginLeft': '0.5em', width: '0.8rem' }} color="#fff" />
                                            }
                                            {item.openinnewtab &&
                                                <FontAwesomeIcon icon={faExternalLinkAlt} style={{ 'marginLeft': '0.5em', width: '0.8rem' }} color="#fff" />
                                            }
                                        </a>
                                    </div>
                                }
                            </div>
                            <div className="column is-6" style={{ 'background': '#fff' }}></div>
                        </div>
                    )
                })
                }
            </>
        }
    </>
)

export default Offerings;