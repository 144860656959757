import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheckCircle
} from '@fortawesome/free-solid-svg-icons';

const Services = ({ services }) => {
    return (
        <div className="card" style={{ height: '100%' }}>
            <header className="card-header">
                <p className="card-header-title is-justify-content-center is-align-content-center">
                    <span className="mr-4">Tjänster</span>
                </p>
            </header>
            <div className="card-content">
                <div className="content">
                    {services != null && services.length > 0 &&
                        <>
                            <ul className="mt-0 ml-0" style={{ 'listStyleType': 'none' }}>
                                {services.map((item, i) => {
                                    return (
                                        <li key={i} className="mb-4">
                                            <FontAwesomeIcon className="icon" icon={faCheckCircle} style={{ 'marginRight': '0.5em', 'verticalAlign': 'middle', 'fontSize': '1.25em' }} color="#48c78e" />
                                            <span style={{ 'verticalAlign': 'middle' }}>
                                                {item.description}
                                                {item.link && item.link !== '' &&
                                                    <Link style={{ marginLeft: '6px' }} to={item.link}>{item.linktext}</Link>
                                                }
                                            </span>
                                        </li>
                                    )
                                })
                                }
                            </ul>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Services;