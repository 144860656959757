import React from 'react'

const OpeningHours = ({ regularHours, deviatingHours }) => {
    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    let futureDate = new Date(today);
    futureDate.setDate(futureDate.getDate() + 30);
    today.setDate(today.getDate() - 7);

    deviatingHours = deviatingHours && deviatingHours.sort((a, b) => new Date(a.deviatingdate) - new Date(b.deviatingdate));
    deviatingHours = deviatingHours && deviatingHours.filter(function (e) {
        let deviatingDate = new Date(e.deviatingdate);
        deviatingDate.setHours(0);
        deviatingDate.setMinutes(0);
        deviatingDate.setSeconds(0);
        deviatingDate.setMilliseconds(0);
        return deviatingDate >= today && deviatingDate <= futureDate;
    });
    return (
        <div className="card" style={{ height: '100%' }}>
            <header className="card-header">
                <p className="card-header-title is-justify-content-center is-align-content-center">
                    <span className="mr-4">Öppettider</span>
                </p>
            </header>
            <div className="card-content">
                <div className="content">
                    {regularHours != null && regularHours.length > 0 &&
                        <>
                            {regularHours.map((item, i) => {
                                const cssClass = item.regularopeninghourstext && item.regularopeninghourstext.toLowerCase() === 'stängt' ? 'has-text-danger' : 'has-text-black';
                                return (
                                    <p key={i} className="is-flex is-flex-direction-row">
                                        <span>
                                            {item.date} {item.name}
                                        </span>
                                        <span className={"is-justify-content-flex-end is-align-items-flex-end ml-auto is-uppercase " + cssClass}>
                                            {item.regularopeninghourstext}
                                        </span>
                                    </p>
                                )
                            })
                            }
                        </>
                    }
                    {deviatingHours != null && deviatingHours.length > 0 &&
                        <>
                            <div className="mt-4">
                                <h4 className="mt-4">Avvikande öppettider</h4>
                                {deviatingHours.map((item, i) => {
                                    const cssClass = item.deviatingopeninghourstext && item.deviatingopeninghourstext.toLowerCase() === 'stängt' ? 'has-text-danger' : 'has-text-black';
                                    return (
                                        <p key={i} className="is-flex is-flex-direction-row">
                                            <span>
                                                {item.deviatingdate} {item.name}
                                            </span>
                                            <span className={"is-justify-content-flex-end is-align-items-flex-end ml-auto is-uppercase textnowrap " + cssClass}>
                                                {item.deviatingopeninghourstext}
                                            </span>
                                        </p>
                                    )
                                })
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default OpeningHours;