import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import Layout from '../components/Layout'
import OpeningHours from '../components/OpeningHours'
import Offerings from '../components/Offerings'
import Services from '../components/Services'

export const IndexPageTemplate = ({
  image,
  title,
  subheading,
  subheadingLink,
  subheadingLinkText,
  opensubheadingLinkInNewTab,
  regularHours,
  deviatingHours,
  offers,
  services,
}) => {
  
  const subheadingLinkTarget = opensubheadingLinkInNewTab ? '_blank' : '';
  return (
    <div>
      <div
        className="full-width-image margin-top-0 hero-image"
        style={{
          backgroundImage: `url(${!!image?.childImageSharp ? image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : image
            })`,
          backgroundColor: '#fff'
        }}
      >
        <div className="hero-usp">
          <h1
            className="has-text-weight-bold is-size-5-mobile is-size-2-tablet is-size-1-widescreen"
            style={{
              backgroundColor: 'rgb(255 255 255 / 80%)',
              boxShadow: 'rgb(255 255 255 / 80%) 0.5rem 0px 0px, rgb(255 255 255 / 80%) -0.5rem 0px 0px',
              borderRadius: '3px',
              color: '#000',
              lineHeight: '1',
              padding: '0.25em',
            }}
          >
            {title}
          </h1>
          <h3
            className="has-text-weight-bold is-size-6-mobile is-size-5-tablet is-size-4-widescreen"
            style={{
              backgroundColor: 'rgb(255 255 255 / 80%)',
              boxShadow: 'rgb(255 255 255 / 80%) 0.5rem 0px 0px, rgb(255 255 255 / 80%) -0.5rem 0px 0px',
              borderRadius: '3px',
              color: '#000',
              lineHeight: '1',
              padding: '0.25em',
            }}
          >
            {subheading}
            {subheadingLink && subheadingLinkText &&
            <Link target={subheadingLinkTarget} className="ml-2" to={subheadingLink} style={{textDecoration: 'underline'}}>{subheadingLinkText}</Link>
            }
          </h3>
        </div>
      </div>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            {
              regularHours &&
              <div className="column is-6">
                <OpeningHours deviatingHours={deviatingHours} regularHours={regularHours}></OpeningHours>
              </div>
            }
            {
              services &&
              <div className="column is-6">
                <Services services={services} />
              </div>
            }
          </div>
        </div>
      </section>
      <section className="offerings">
        <Offerings offers={offers} />
      </section>
    </div>
  );
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subheading: PropTypes.string,
  hours: PropTypes.array,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const hours = data.hours.frontmatter;

  return (
    <Layout pageKeywords={frontmatter.seokeywords}>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        subheadingLink={frontmatter.subheadinglink}
        subheadingLinkText={frontmatter.subheadinglinktext}
        opensubheadingLinkInNewTab={frontmatter.opensubheadinglinkinnewtab}
        regularHours={hours.regularopeninghours}
        deviatingHours={hours.deviatingopeninghours}
        offers={frontmatter.offers}
        services={frontmatter.services}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`query IndexPageTemplate {
  markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
    frontmatter {
      seokeywords
      title
      subheadinglink
      subheadinglinktext
      opensubheadinglinkinnewtab
      image {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      services {
        description
        link
        linktext
      }
      offers {
        title
        description
        ctabuttontext
        ctabuttonlink
        openinnewtab
      }
      subheading
    }
  }
  hours: markdownRemark(frontmatter: {templateKey: {eq: "openinghours-page"}}) {
    frontmatter {
      deviatingopeninghours {
        deviatingdate(formatString: "YYYY-MM-DD")
        deviatingopeninghourstext
        name
      }
      regularopeninghours {
        name
        regularopeninghourstext
      }
    }
  }
}
`